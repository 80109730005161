import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    getDefaultSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_default_social', { params })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getListSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_list_social', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    getSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/get_social', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    addSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/add_social', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    editSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/edit_social', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    deleteSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/delete_social', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    socialOptions(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post('/cms/social_options', params)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    uploadImageSocial(ctx, params) {
      return new Promise((resolve, reject) => {
        axios
          .post(`/cms/upload_image_social/${params.id}`, params.data, {
            headers: {
              'Content-Type': 'multipart/form-data',
            },
          })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
